.documentListItem {
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 70px;
}
.documentListItem:hover{
    background-color: #f5f5f5;
}
.documentListItem.active {
    background-color: #ebebeb;
}
.documentListItem--avatar{
width: 50px;
height: 50px;
margin-left: 15px;
}
.documentListItem--lines{
flex: 1;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
border-bottom: 1px solid #EEE;
padding-right: 15px;
margin-left: 15px;

flex-wrap: wrap;
min-width: 0;
}
.documentListItem--line{
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
}
.documentListItem--name{
overflow: hidden;
font-size: 17px;
color: #000;
}
.documentListItem--date{
font-size: 12px;
color:#999;
}
.documentListItem--lastMsg{
 font-size: 14px;
color: #999;
display: flex;
width: 100%;
}
.documentListItem--lastMsg p{
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   margin: 0;
}